import React from 'react';
import {authContext} from '../../../adalConfig';
import './Views.css';


export default class UpdatingPermissions extends React.Component {

    componentDidMount() {
        setTimeout(this.reload, 3000);
    }

    logout = async () => {
        await localStorage.removeItem('accessToken');
        await localStorage.removeItem('user');
        authContext.logOut();
    };

    reload = () => {
        window.location.reload();
    };

    render() {
        return (
            <div className="custom-view">
                <h3>Your user permissions have been updated. This page will reload.</h3>
                <p>If you are not redirected automatically, <a onClick={this.reload}>click here</a>.
                    If the issue still persists, please try <a onClick={this.logout}>logging out</a> or contacting your
                    system administrator.
                </p>
            </div>
        )
    }
}