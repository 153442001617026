import React, {Component} from "react";
import CryptoJS from "crypto-js";
import * as qs from 'querystring';
import Header from '../Shared/Header/Header';
import Footer from '../Shared/Footer/Footer';
import ReportLanding from './ReportLanding/ReportLanding';
import ReportThumb from './ReportThumb/ReportThumb';
import DashboardViewer from "./DashboardViewer";
import Loader from "../Shared/Loader/Loader";
import DataCorrupted from "../Shared/Views/DataCorrupted";


export default class Report extends Component {
    constructor(props) {
        super(props);

        try {

            let encryptedUser = localStorage.getItem('user');
            let decryptBytes = CryptoJS.AES.decrypt(encryptedUser.toString(), process.env.REACT_APP_SECERT_KEY);
            let user = (JSON.parse(decryptBytes.toString(CryptoJS.enc.Utf8)))

            this.state = {
                user: user,
                filters: null,
                report: null,
                reports: [],
                reportCount: 0,
                dbcDefaultIndex: 0,
                experientialDefaultIndex: 0,
                dataError: false
            }
        } catch (err) {
            console.log("err ====> ", err);
            this.state = {
                dataError: true
            }
        }
    };

    getPermissionsData = () => {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) return;
        this.setState({_isLoading: true});
        fetch(process.env.REACT_APP_SERVER_API_URL + 'product-groups/data',{
            method: "GET",
            headers: {
                'Authorization': accessToken,
            },
        }).then(response => response.json()
        ).then(responseJson => {
            let filters = process.env.REACT_APP_REPORT_PERMISSIONS.split(',');
            let levels = this.state.user.profile.permission.selectAll;
            let query = new Map();
            let prettyNames = JSON.parse(responseJson['data']);
            filters.forEach(key=>{
                //for testing only on my own account
                // if(key.toLowerCase() ==='brands'){
                //     levels[key.toLowerCase()] = false;
                // }
                if(!levels[key.toLowerCase()]){
                    let itemString = '';
                    this.state.user.profile.permission[key.toLowerCase()].forEach(id =>{
                        prettyNames[key.toLowerCase()].forEach(item=>{
                            if(item._id===id){
                                itemString+=item.prettyName+','
                            }
                        })

                    });
                    query[key]=itemString;
                }
            });
            this.setState({filters: query})
        }).catch(err => {
            console.log('err =', err);
        })
    };

    componentDidMount() {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken || this.state.dataError) return;

        this.getPermissionsData();

        fetch(process.env.REACT_APP_SERVER_API_URL + 'report/' + this.state.user.roleId, {
            method: 'GET',
            headers: {
                'Authorization': accessToken
            }
        }).then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                return response.json().then(Promise.reject.bind(Promise));
            }
        }).then((responseJson) => {
            const responseReports = JSON.parse(responseJson['data']);

            let firstDBCreportIndex, firstExperientialReportIndex;
            for (const i in responseReports) {
                if (responseReports[i].name.startsWith('dbc')) {
                    firstDBCreportIndex = i;
                    break;
                }
            }
            for (const i in responseReports) {
                if (responseReports[i].name.startsWith('experiential')) {
                    firstExperientialReportIndex = i;
                    break;
                }
            }
            this.setState({
                reports: responseReports,
                dbcDefaultIndex: firstDBCreportIndex,
                experientialDefaultIndex: firstExperientialReportIndex,
                reportCount: responseReports.length
            });
        }).catch((err) => {
            console.log("err ====> ", err);
            this.setState({dataError: true});
        });
    }

	handleChangePage = async (page) => {

    };

    findReportByName = (name, page) => {
        const reportIndex = this.state.reports.findIndex(r => name ? r.name === name : r.productGroup === page);
        return this.state.reports[reportIndex];
    };

    pageView = () => {
        const pageName = this.props.match.params.category || 'landing';
        const report = this.findReportByName(this.props.match.params.report, pageName);
        const params = qs.parse(this.props.location.search.substr(1));

        let filters = this.state.filters;

        Object.keys(params).forEach(key=>{
            if (filters && !filters.has(key)) {
                filters[key] = params[key]
            }
        });
        console.log(filters);

		return(
            pageName === 'landing' ?
				<ReportLanding handleClick={this.handleChangePage} />
				:
				<div>
					{this.state && this.state.reportCount > 1 && report ?
						<ReportThumb reports={this.state.reports} page={pageName} currentReport={report}
						/> : <div/>
					}
					{this.state && report ? <DashboardViewer report = {report} params = {filters}/> : <Loader />}
				</div>
		);
    };

    render() {
        return (
            this.state.dataError ? <DataCorrupted/> :

                <div className="app">
                    <div className="header">
                        <Header user={this.state.user}/>
                    </div>

                    {this.pageView()}

                    <Footer role={this.state.user.role}/>
                </div>
        )
    }
}
