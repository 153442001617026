import React, {Component} from "react";
import {Checkbox, Tooltip} from "@material-ui/core";
import {toast} from "react-toastify";
import moment from "moment";
import copy from "copy-to-clipboard";

import PhotoEnlarger from "../PhotoEnlarger/PhotoEnlarger";
import PermissionAwarePage from "../../Shared/PermissionAwarePage/PermissionAwarePage";

import {Link, VerifiedUser, RotateLeft, RotateRight} from "@material-ui/icons";
import "./RenderedPhotos.css"
import Loader from "../../Shared/Loader/Loader";


export default class RenderedPhotos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rotatingPhotos: new Set(),
            expandedSrc: null,
            expandedData: null
        }
    }

    componentDidMount(){
        document.addEventListener("keydown", this.handleEscKey, false);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.handleEscKey, false);
    }

    handleEscKey = (e) => {
        if (e.keyCode === 27 && this.state.expandedSrc) {
            this.closePhoto();
        }
    }

    expandPhoto = (data) => {
        const src = data.displayUrl;

        if (src) {
            this.setState({
                expandedSrc: src,
                expandedData: data
            });
        }
    }

    closePhoto = () => {
        this.setState({
            expandedSrc: null,
            expandedData: null
        });
    }

    copyLink = (url) => {
        copy(url);
        toast("Photo Link Copied to Clipboard", {className: "notification success"});
    }

    rotatePhoto = (photo, degrees) => {
        toast("Rotating Photo", {className: "notification success"});

        let {rotatingPhotos} = this.state;
        rotatingPhotos.add(photo._id);
        this.setState({rotatingPhotos: rotatingPhotos});

    	const accessToken = localStorage.getItem('accessToken');
    	if (!accessToken) return;

    	fetch(process.env.REACT_APP_FILE_MANAGEMENT_API_URL + `file/${photo._id}?rotate=${degrees}`, {
    		method: 'PUT',
    		headers: {
    			'Authorization': `Bearer ${accessToken}`,
    			'Application': 'photoportal'
    		},
    	}).then(response => {
    	    if (response.ok) {
                this.props.fetchPhoto(true);
                rotatingPhotos.delete(photo._id);
                this.setState({rotatingPhotos: rotatingPhotos});
                return;
    		}

    	    throw "Response Error";
    	}).catch(err => {
    		console.log("Error: " + err);
    		toast("Error rotating photo", {className: "notification error"});
            rotatingPhotos.delete(photo._id);
    		this.setState({rotatingPhotos: rotatingPhotos});
    	});
    }

    render() {
        let renderedPhotos = <span className="fallback-message">No results found</span>;

        if (this.props.photos.length > 0) {
            renderedPhotos = this.props.photos.map((photo, i) => {
                let rotating = this.state.rotatingPhotos.has(photo._id);
                return (
                    <div key={i} className="item">
                        <div className='img-wrapper'>
                            <label htmlFor={photo.hashName}>
                                <Checkbox checked={this.props.selectedPhoto.has(photo.hashName)}
                                          onChange={() => this.props.selectPhoto(photo.hashName)}
                                          value={photo.hashName} type="checkbox"
                                          color="primary" id={photo.hashName}/>
                            </label>
                            <div className="verification-icon" disabled={!photo.verified}>
                                <VerifiedUser htmlColor="#4492ec"/>
                            </div>
                            {rotating ? <Loader singlePhotoLoader={true}/> :
                                <PermissionAwarePage userPermission={this.props.toolAccess} allowedPermission={["Admin", "Write"]}>
                                    <div className={'left-rotate'}>
                                        <Tooltip title="Rotate Left">
                                            <RotateLeft htmlColor="#4492ec" style={{fontSize: 30}}
                                                        onClick={() => this.rotatePhoto(photo, 90)}
                                                        disabled={this.props.selectedPhoto.size === 0}/>
                                        </Tooltip>
                                    </div>
                                    <div className={'right-rotate'}>
                                        <Tooltip title="Rotate Right">
                                            <RotateRight htmlColor="#4492ec" style={{fontSize: 30}}
                                                         onClick={() => this.rotatePhoto(photo, -90)}
                                                        disabled={this.props.selectedPhoto.size === 0}/>
                                        </Tooltip>
                                    </div>
                                </PermissionAwarePage>
                            }
                            <img src={photo.thumbnailUrl} onClick={() => this.expandPhoto(photo)}
                                 alt="Didn't load correctly"/>
                        </div>

                        <div className="item-info">
                            <ul className="half">
                                <li><span>Brand:</span> {photo.brandId && this.props.brands ?
                                    this.props.brands.get(photo.brandId).prettyName : 'n/a'}</li>
                            </ul>
                            <ul className="half">
                                <li><span>Channel:</span> {photo.channelId && this.props.channels ?
                                    this.props.channels.get(photo.channelId).prettyName : 'n/a'}</li>
                            </ul>
                            <ul className="half">
                                <li><span>Market:</span> {photo.marketId && this.props.markets ?
                                    this.props.displayMarkets.get(photo.marketId).prettyName : 'n/a'}</li>
                            </ul>
                            <ul className="half">
                                <li><span>Event ID:</span> {photo.selectors.eventid ?
                                    photo.selectors.eventid : 'n/a'}</li>
                            </ul>
                            <ul className="full">
                                <li><span>Event Date:</span> {photo.eventDate ?
                                    moment(photo.eventDate).format('MMM Do YYYY') : 'n/a'}</li>
                            </ul>
                            <div className={"link-icon"}>
                                <Tooltip title="Copy Link to Clipboard">
                                    <Link htmlColor="#4492ec" onClick={() => this.copyLink(photo.originalUrl)}
                                          disabled={this.props.selectedPhoto.size === 0}/>
                                </Tooltip>

                            </div>
                        </div>
                    </div>
                )
            });
        }

        return (
            <div className={this.props.status} id="photos">
                {renderedPhotos}

                <PhotoEnlarger
                    brands={this.props.brands} channels={this.props.channels} markets={this.props.markets} displayMarkets={this.props.displayMarkets}
                    campaigns={this.props.campaigns} tags={this.props.tags}
                    source={this.state.expandedSrc} data={this.state.expandedData}
                               onUpdate={() => this.closePhoto()}
                />
            </div>
        )
    }
}