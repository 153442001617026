import React, {Component} from 'react';
import {Checkbox, CheckboxGroup as Checkboxes} from 'react-checkbox-group';
import './CheckboxPermissions.css';


export default class CheckboxPermissions extends Component {
	constructor(props) {
		super(props);

		let data;
		if(typeof props.matchMarkets !== 'undefined'){
			data = props.data.filter(datum => {
				return props.matchMarkets.includes(datum.marketId)
			});
		}
		else {
			data = props.target === "productGroups" || props.target === "departments" || props.target === "channels" ?
				props.data : props.data.filter(datum => {
					return props.acceptedProductGroups.includes(datum.productGroupId)
				});
		}

		this.state = {
			data: data.sort(CheckboxPermissions.compare),
			allData: props.data,
			selectAllValue: data.map(el => el._id)
		};
	}

	componentDidMount() {
		if (this.props.selectAll) {
			this.props.onChange(this.props.target, this.state.allData.map(el => el._id), true);
			if (this.props.acceptedProductGroups || this.props.matchMarkets) {
				this.updateData()
			}
		}
	}

	dataSelect = (selectedData) => {
		let areEqual = this.isEquivalent(this.state.selectAllValue, selectedData);
		if (selectedData) {
			this.props.onChange(this.props.target, selectedData, areEqual);
		}
	}

	isEquivalent = (arr1, arr2) => {
		if (arr2 && arr1 && Array.isArray(arr2) && Array.isArray(arr1)) {
			if (Object.keys(arr2).length === Object.keys(arr1).length) {
				return arr2.reduce((result, actual) => {
					return arr1.includes(actual) && result
				}, true)
			}
			return false
		}
		return false
	}

	toggleSelectAll = () => {
		let selectAllValue = this.state.selectAllValue;
		if (typeof this.props.matchMarkets !== 'undefined') {
			selectAllValue = this.state.data.filter((d) => this.props.matchMarkets.indexOf(d.marketId) >= 0).map((d) => d._id);
		}
		let areEqual = this.isEquivalent(selectAllValue, this.props.value);

		if (areEqual) {
			this.props.onChange(this.props.target, [], false)
		} else {
			this.props.onChange(this.props.target, selectAllValue, true)
		}
	}

	renderCheckbox = (datum, i) => {
		if (typeof this.props.matchMarkets !== 'undefined') {
			if (this.props.matchMarkets.indexOf(datum.marketId) < 0) return;
		}
		let nameString = datum.prettyName;
		if (this.props.productGroups) {
			let productGroup = this.props.productGroups.find(pg => pg._id === datum.productGroupId);
			nameString += " (" + productGroup.prettyName + ")"
		}
		return (
			<label htmlFor={datum._id} className="checkbox-wrapper" key={i}>
				<Checkbox id={datum._id} value={datum._id}/>
				<span></span>
				{nameString}
			</label>
		)
	}

	componentDidUpdate(prevProps, prevState) {
		if (JSON.stringify(prevProps.acceptedProductGroups) !== JSON.stringify(this.props.acceptedProductGroups) ||
			JSON.stringify(prevProps.matchMarkets) !== JSON.stringify(this.props.matchMarkets)) {
			this.updateData()
		}
	}

	updateData = () => {
		let newData = this.state.allData.filter(datum => {
			return this.props.acceptedProductGroups ? this.props.acceptedProductGroups.includes(datum.productGroupId) :
				this.props.matchMarkets.includes(datum.marketId)
		});
		this.setState({data: newData.sort(CheckboxPermissions.compare), selectAllValue: newData.map(el => el._id)}, () => {
			this.deselectUnusedData()
		})
	}

	deselectUnusedData = () => {
		// check for select all
		let {allData} = this.state;
		let {value, acceptedProductGroups, matchMarkets} = this.props;
		let newValue = value.filter(dataId => {
			let valueData = allData.find(datum => datum._id === dataId);
			if (!valueData){
				return false;
			}
			return acceptedProductGroups ? acceptedProductGroups.includes(valueData.productGroupId) :
				matchMarkets.includes(valueData.marketId)
		});
		let areEqual = this.state.selectAllValue.length > 0 && this.isEquivalent(this.state.selectAllValue, newValue);
		this.props.onChange(this.props.target, newValue, areEqual)
	}

	renderCheckboxBody = () => {
		if (this.state.data.length <= 0) {
			return this.props.acceptedProductGroups ?
				<p className="checkbox-group-empty">
					First select a Product Group to set {this.props.label.slice(0, -1)} permissions.
				</p> :
				<p className="checkbox-group-empty">
					First select a Market to set {this.props.label.slice(0, -1)} permissions.
				</p>
		} else {
			return (
				<Checkboxes onChange={this.dataSelect} checkboxDepth={2} value={this.props.value}>
					{this.state.data && this.state.data.map((datum, i) => this.renderCheckbox(datum, i))}
				</Checkboxes>
			)
		}
	}

	static compare(a, b) {
		if (a.prettyName < b.prettyName) return -1;
		if (a.prettyName > b.prettyName) return 1;
		return 0
	}

	render() {
		return (
			<div className={`checkbox-group ${this.props.selectedRole === 'admin' ? 'disabled' : ''}`}>

				<div className={`checkbox-group-header ${this.props.selectedRole === 'admin' ? 'disabled' : ''}`}>

					<span className="label">{this.props.label}</span>

					<label htmlFor={`selectAllMonths${this.props.target}`} className="checkbox-wrapper">
						<input type="checkbox" id={`selectAllMonths${this.props.target}`}
							   checked={this.state.selectAllValue.length > 0 && this.isEquivalent(this.props.value, this.state.selectAllValue)}
							   onChange={this.toggleSelectAll} disabled={this.state.data.length <= 0}/>
						<span/>
						<p className="selectAll">Select All</p>
					</label>

				</div>
				<div className={`checkbox-group-body ${this.props.selectedRole === 'admin' ? 'disabled' : ''}`}>
					{this.renderCheckboxBody()}
				</div>

			</div>

		)
	}

}
