import React, {Component} from 'react';
import ReactModal from 'react-modal';
import CryptoJS from "crypto-js";
import {authContext} from "../../adalConfig";
import {toast, ToastContainer} from "react-toastify";
import saveAs from "file-saver";
import {Checkbox, Tooltip} from "@material-ui/core";

import PhotoUpload from './PhotoUpload/PhotoUpload';
import RenderedPhotos from "./RenderedPhotos/RenderedPhotos";
import {getFilterParams} from '../../utils';
import FilterBar from './FilterBar/FilterBar';
import Verification from './Verification/Verification';
import Header from '../Shared/Header/Header';
import Footer from '../Shared/Footer/Footer';
import Loader from '../Shared/Loader/Loader';
import Pagination from '../Shared/Pagination/Pagination';
import SearchBar from '../Shared/SearchBar/SearchBar';
import DataCorrupted from "../Shared/Views/DataCorrupted";
import PermissionAwarePage from "../Shared/PermissionAwarePage/PermissionAwarePage";
import SortByDropdown from "./SortByDropdown/SortByDropdown";

import {DeleteOutlined, SaveAltOutlined} from '@material-ui/icons';
import './PhotoGallery.css';


export default class PhotoGallery extends Component {
	constructor(props) {
		super(props);

		try {
			let encryptedUser = localStorage.getItem('user');
			let decryptBytes = CryptoJS.AES.decrypt(encryptedUser.toString(), process.env.REACT_APP_SECERT_KEY);
			let user = (JSON.parse(decryptBytes.toString(CryptoJS.enc.Utf8)));

			this.state = {
				user: user,
				toolAccess: user.profile.photoportal.access,
				showOnlyVerified: user.profile.photoportal.access === "Read" ? 'verified' : null,
				brands: null,
				channels: null,
				markets: null,
				// TODO This is only temporary
				displayMarkets: null,
				campaigns: null,
				tags: null,

				photos: [],
				filterParams: getFilterParams(),
				label: "Photos",
				page: 1,
				limit: 20,
				total: 0,
				pages: 0,
				itemDisplayed: 0,
				search: "",
				sortBy: {value: 'eventDate', label: 'Event Date'},
				sortOrder: -1,
				selectedPhoto: new Set(),

				isLoading: false,
				isDownloading: false,
				modalIsOpen: false,

				dataError: false
			}
		} catch (err) {
			console.log("err ====> ", err);
			this.state = {
				dataError: true
			}
		}
	}

	fetchPhoto = (dontScroll) => {
		const accessToken = localStorage.getItem('accessToken');
		if (!accessToken || this.state.dataError) return;

		let filterParams = this.state.filterParams ? this.state.filterParams : '';

		this.setState({isLoading: true});
		let url = process.env.REACT_APP_SERVER_API_URL + 'files?' + filterParams + `&page=${this.state.page}` +
			`&limit=${this.state.limit}&sortBy=${this.state.sortBy.value}&sortOrder=${this.state.sortOrder}` +
			`${this.state.search === '' ? '' : "&search=" + this.state.search}`;

		if (this.state.showOnlyVerified !== null) {
			url = url + `&verified=${this.state.showOnlyVerified}`;
		}

		fetch(url, {
			method: 'GET',
			headers: {
				'Authorization': accessToken
			},
		}).then(response => {
			if (response.status === 498) {
				authContext._renewIdToken((err, token) => {
					this.fetchPhoto();
				});
			} else if (response.ok) {
				return response.json();
			} else {
				return response.json().then(Promise.reject.bind(Promise));
			}
		}).then(responseJson => {
			this.setState({
				photos: responseJson.data.fileList,
				total: responseJson.data.total,
				pages: responseJson.data.pages,
				page: responseJson.data.page,
				itemDisplayed: responseJson.data.fileList.length,
				isLoading: false,
				selectedPhoto: new Set()
			});
			//Scroll back to top with each fetch
			if(!dontScroll) {
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			}
		}).catch(err => {
			console.log("Error: " + err)
			toast("Error loading photos", {className: "notification error"});
			this.setState({
				isLoading: false,
				dataError: true
			});
		});
	}

	fetchBrand = (accessToken) => {
		return fetch(process.env.REACT_APP_SERVER_API_URL + "brand", {
			method: 'GET',
			headers: {
				'Authorization': accessToken,
			}
		})
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					return response.json().then(Promise.reject.bind(Promise));
				}
			})
			.then((responseJson) => {
				if (this.state.isMounted) {
					let brandMap = new Map();
					responseJson['data'].forEach((brand) => {
						brandMap.set(brand._id, brand);
					});
					this.setState({
						brands: brandMap
					});
				} else {
					console.log('unmounted, dont set');
				}
			})
			.catch(err => {
				console.log("Error: " + err)
				this.setState({
					dataError: true
				});
			});
	}

	fetchChannel = (accessToken) => {
		return fetch(process.env.REACT_APP_SERVER_API_URL + "channel", {
			method: 'GET',
			headers: {
				'Authorization': accessToken,
			}
		}).then((response) => {
			if (response.ok) {
				return response.json();
			} else {
				return response.json().then(Promise.reject.bind(Promise));
			}
		}).then((responseJson) => {
			if (this.state.isMounted) {
				let channelMap = new Map();
				responseJson['data'].forEach((channel) => {
					channelMap.set(channel._id, channel);
				});
				this.setState({
					channels: channelMap,
				});
			} else {
				console.log('unmounted, dont set');
			}
		}).catch(err => {
			console.log("Error: " + err)
			this.setState({
				dataError: true
			});
		});
	}

	fetchMarket = (accessToken) => {
		return fetch(process.env.REACT_APP_SERVER_API_URL + 'market', {
			method: 'GET',
			headers: {
				'Authorization': accessToken
			}
		}).then((response) => {
			if (response.ok) {
				return response.json();
			} else {
				return response.json().then(Promise.reject.bind(Promise));
			}
		}).then((responseJson) => {
			if (this.state.isMounted) {
				let marketMap = new Map();
				let displayMarketMap = new Map();
				responseJson['data'].forEach((market) => {
					//TODO This is only temporary
					if(market.productGroupId === "5ae0edf1bd872e873e698bb8") {
						marketMap.set(market._id, market);
					}
					displayMarketMap.set(market._id, market);
				})
				this.setState({
					markets: marketMap,
					displayMarkets: displayMarketMap
				});
			} else {
				console.log('unmounted, dont set');
			}
		}).catch(err => {
			console.log("Error: " + err)
			this.setState({
				dataError: true
			});
		});
	}

	fetchCampaign = (accessToken) => {
		return fetch(process.env.REACT_APP_SERVER_API_URL + "campaign", {
			method: 'GET',
			headers: {
				'Authorization': accessToken,
			}
		}).then((response) => {
			if (response.ok) {
				return response.json();
			} else {
				return response.json().then(Promise.reject.bind(Promise));
			}
		}).then((responseJson) => {
			if (this.state.isMounted) {
				let campaignMap = new Map();
				responseJson['data'].forEach((campaign) => {
					campaignMap.set(campaign._id, campaign);
				});
				this.setState({
					campaigns: campaignMap
				});
			} else {
				console.log('unmounted, dont set');
			}
		}).catch(err => {
			console.log("Error: " + err)
			this.setState({
				dataError: true
			});
		});
	}

	fetchTag = (accessToken) => {
		return fetch(process.env.REACT_APP_SERVER_API_URL + "tag", {
			method: 'GET',
			headers: {
				'Authorization': accessToken
			}
		}).then((response) => {
			if (response.ok) {
				return response.json();
			} else {
				return response.json().then(Promise.reject.bind(Promise));
			}
		}).then((responseJson) => {
			if (this.state.isMounted) {
				let tagMap = new Map();
				responseJson['data'].forEach((tag) => {
					tagMap.set(tag._id, tag);
				})
				this.setState({
					tags: tagMap,
				});
			} else {
				console.log('unmounted, dont set');
			}
		}).catch(err => {
			console.log("Error: " + err)
			this.setState({
				dataError: true
			});
		});
	}

	onPageUpdate = (data) => {
		if (isNaN(data.page)) return;
		if (+data.page > this.state.pages) {
			data.page = this.state.pages;
		}
		if (+data.page < 1) {
			data.page = 1;
		}
		this.setState({page: data.page}, () => {
			this.fetchPhoto();
		});
	}

	handleSearch = (value) => {
		this.setState({search: value, page: 1}, () => {
			this.fetchPhoto();
		});
	}

	filterUpdate = () => {
		const filterParams = getFilterParams();
		this.setState({
			filterParams: filterParams,
			page: 1
		});
	}

	async componentDidMount() {
		this.setState({isMounted: true});
		const accessToken = localStorage.getItem('accessToken');
		if (!accessToken || this.state.dataError) return;

		this.fetchPhoto();
		await Promise.all([
			this.fetchBrand(accessToken),
			this.fetchChannel(accessToken),
			this.fetchMarket(accessToken),
			this.fetchCampaign(accessToken),
			this.fetchTag(accessToken)
		])
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.filterParams !== this.state.filterParams) {
			this.fetchPhoto();
		}
	}

	componentWillUnmount() {
		this.setState({isMounted: false});
		console.log('photo unmount');
	}

	openModal = () => {
		this.setState({modalIsOpen: true});
	}

	closeModal = () => {
		this.setState({modalIsOpen: false});
	}

	downloadPhoto = () => {
		let photoSet = this.state.selectedPhoto;
		let fileParams = "files=";

		const accessToken = localStorage.getItem('accessToken');
		if (!accessToken) return;

		for (const photoHash of photoSet.keys()) {
			fileParams = fileParams + photoHash + ","
		}
		fileParams = fileParams.slice(0, -1);

		this.setState({isDownloading: true});

		fetch(process.env.REACT_APP_FILE_MANAGEMENT_API_URL + `file?${fileParams}`, {
			method: 'GET',
			headers: {
				'Authorization': `Bearer ${accessToken}`
			},
		}).then(async response => {
			if (response.status === 498) {
				authContext._renewIdToken((err, token) => {
					this.downloadPhoto();
				});
			} else if (response.ok) {
				const filename = response.headers.get('content-disposition').split(';')[1].trim().split('=')[1];
				let blob = await response.blob();
				saveAs(new Blob([blob], {type: blob.type}), filename);

				photoSet.clear();
				this.setState({
					selectedPhoto: photoSet,
					isDownloading: false
				});
				return [response.headers.get('content-disposition'), blob];
			} else {
				return response.json().then(Promise.reject.bind(Promise));
			}
		}).catch(err => {
			console.log("Error: " + err)
			toast("Error downloading photos", {
				className: "notification error"
			});
			this.setState({isDownloading: false});
		});
	}

	deletePhoto = () => {
		let photoSet = this.state.selectedPhoto;
		let fileParams = "files=";

		const accessToken = localStorage.getItem('accessToken');
		if (!accessToken) return;

		for (const photoHash of photoSet.keys()) {
			fileParams = fileParams + photoHash + ","
		}
		fileParams = fileParams.slice(0, -1);

		this.setState({isLoading: true});

		fetch(process.env.REACT_APP_FILE_MANAGEMENT_API_URL + `file?${fileParams}`, {
			method: 'DELETE',
			headers: {
				'Authorization': `Bearer ${accessToken}`
			},
		}).then(response => {
			if (response.status === 498) {
				authContext._renewIdToken((err, token) => {
					this.deletePhoto();
				});
			} else if (response.ok) {
				photoSet.clear();
				this.setState({
					selectedPhoto: photoSet,
					isLoading: false
				});
				this.fetchPhoto();
			}
		}).catch(err => {
			console.log("Error: " + err)
			toast("Error deleting photos", {
				className: "notification error"
			});
			this.setState({isLoading: false});
		});
	}

	selectPhoto = (hash) => {
		let photoSet = this.state.selectedPhoto;
		if (photoSet.has(hash)) {
			photoSet.delete(hash);
		} else {
			photoSet.add(hash);
		}

		this.setState({
			selectedPhoto: photoSet
		})
	}

	selectAllPhoto = (isAllSelected) => {
		let photoSet = this.state.selectedPhoto;
		if (isAllSelected) {
			photoSet.clear();
		} else {
			this.state.photos.map((photo, i) => {
				photoSet.add(photo.hashName);
			})
		}
		this.setState({
			selectedPhoto: photoSet
		})
	}

	clearSelectedPhotos = () => {
		this.setState({
			selectedPhoto: new Set()
		});
		this.fetchPhoto(true);
	}

	changeSortBy = (value) => {
		this.setState({
			page: 1,
			sortBy: value
		}, () => this.fetchPhoto());
	}

	changeSortOrder = () =>{
		this.setState({
			page: 1,
			sortOrder: this.state.sortOrder * -1
		}, () => this.fetchPhoto());
	}

	render() {
		let classNames = 'photos';
		if (this.state.isLoading) classNames += ' is-loading';

		return (
			this.state.dataError ? <DataCorrupted/> :

				<div className="app">
					<div className="header">
						<Header user={this.state.user}/>
					</div>
					{(this.state && this.state.brands && this.state.channels && this.state.markets && this.state.campaigns) ?
						<div className="photo-gallery">

							<ToastContainer autoClose={2000}/>

							<FilterBar onUpdate={this.filterUpdate} toolAccess={this.state.toolAccess}
									   brands={this.state.brands} channels={this.state.channels}
									   markets={this.state.markets} campaigns={this.state.campaigns} tags={this.state.tags}/>

							<div className="sticky-wrapper">
								<div className="input-wrapper">
									<SearchBar
										search={this.state.search}
										onChange={this.handleChange}
										onSearch={this.handleSearch}
										placeholder="Search Photos"
									/>
									<PermissionAwarePage userPermission={this.state.toolAccess} allowedPermission={["Admin", "Write"]}>
										<Verification selectedPhoto={this.state.selectedPhoto} clearPhoto={this.clearSelectedPhotos}/>
									</PermissionAwarePage>

									{this.state.isDownloading ?
										<img src={require("../../media/img/misc/spinner-grey.gif")}
											 disabled={this.state.selectedPhoto.size === 0} alt="Loading"/>
										:
										<Tooltip title='Download'>
											<SaveAltOutlined htmlColor="#4492ec" onClick={this.downloadPhoto}
															 disabled={this.state.selectedPhoto.size === 0}/>
										</Tooltip>
									}

									<PermissionAwarePage userPermission={this.state.toolAccess} allowedPermission={["Admin", "Write"]}>
										<Tooltip title="Delete">
											<DeleteOutlined htmlColor="#4492ec" onClick={this.deletePhoto}
															disabled={this.state.selectedPhoto.size === 0}/>
										</Tooltip>
									</PermissionAwarePage>
								</div>
							</div>
							<div className="selectAll-Pagination-row">
								< Tooltip title="Select All">
									<Checkbox checked={this.state.photos.length === this.state.selectedPhoto.size}
											  onChange={() => this.selectAllPhoto(this.state.photos.length === this.state.selectedPhoto.size)}
											  value={this.state.photos.length === this.state.selectedPhoto.size}
											  type="checkbox"
											  color="primary" id="selectAll" disabled={this.state.photos.length === 0}
									/>
								</Tooltip>

								<SortByDropdown value={this.state.sortBy} onChange={this.changeSortBy} changeSortOrder={this.changeSortOrder}/>

								<Pagination {...this.state} onUpdate={this.onPageUpdate}/>
							</div>

							<RenderedPhotos
								brands={this.state.brands} channels={this.state.channels} markets={this.state.markets} displayMarkets={this.state.displayMarkets}
								campaigns={this.state.campaigns} tags={this.state.tags}
								status={classNames} toolAccess={this.state.toolAccess}
								photos={this.state.photos} selectedPhoto={this.state.selectedPhoto}
								selectPhoto={this.selectPhoto} fetchPhoto={this.fetchPhoto}
							/>

							<PermissionAwarePage userPermission={this.state.toolAccess}
												 allowedPermission={["Admin", "Write"]}>
								<Tooltip title="Add Photo">
									<div className="fab" onClick={this.openModal}>+</div>
								</Tooltip>
							</PermissionAwarePage>

							<Pagination {...this.state} onUpdate={this.onPageUpdate}/>

							<ReactModal
								isOpen={this.state.modalIsOpen}
								onRequestClose={this.closeModal}
								ariaHideApp={false}
								style={{
									overlay: {zIndex: 100}
								}}>
								<PhotoUpload brands={this.state.brands} channels={this.state.channels}
											 markets={this.state.markets} campaigns={this.state.campaigns}
											 closeModal={this.closeModal} reloadPhoto={this.fetchPhoto}
											 userId={this.state.user}/>
							</ReactModal>
						</div> : <Loader/>
					}

					<Footer role={this.state.user.role}/>
				</div>
		)
	}
}