import React, { Component } from 'react';
import DetectClickOutside from 'react-click-outside';
import moment from 'moment';

import './PhotoEnlarger.css';


class PhotoEnlarger extends Component {
    detectClick = (e) => {
        if (e.target.tagName !== 'IMG') {
            this.props.onUpdate();
        }
    }

    buildTagDisplay = (tagIds) => {
        let display = '';
        if(tagIds && Object.keys(tagIds).length > 0) {
            Object.keys(tagIds).forEach((id) => {
                if(tagIds[id] && this.props.tags.get(id)) {
                    display = display + ' ' + this.props.tags.get(id).prettyName
                }
            })
        }
        if(display === '')
            return 'n/a'
        return display
    }

    render() {
        const data = this.props.data;

        return (
            (!data) ? <div /> :

            <div onClick={this.detectClick} className={this.props.source == null ? "enlarged-wrapper" : "enlarged-wrapper is-enlarged"}>
                <div className="enlarged-inner">

                    <img className="image" src={this.props.source} alt="" />

                    <div className="details">
                        <ul>
                            {/*<li><span>File Name:</span> {data.name ? data.name : 'n/a'}</li>*/}
                            <li><span>Brand:</span> {data.brandId ? this.props.brands.get(data.brandId).prettyName : 'n/a'}</li>
                            <li><span>Channel:</span> {data.channelId ? this.props.channels.get(data.channelId).prettyName : 'n/a'}</li>
                            <li><span>Event ID:</span> {data.selectors.eventid ? data.selectors.eventid : 'n/a'}</li>
                        </ul>
                        <ul>
                            <li><span>Market:</span> {data.marketId ? this.props.displayMarkets.get(data.marketId).prettyName : 'n/a'}</li>
                            <li><span>Program:</span>
                                {data.campaignId ? this.props.campaigns.get(data.campaignId) ? this.props.campaigns.get(data.campaignId).prettyName: 'Program not found' : 'n/a'}</li>
                            <li><span>Tags:</span> {this.buildTagDisplay(data.tagIds)} </li>
                        </ul>
                        <ul>
                            <li><span>Event Date:</span> {data.eventDate ? moment(data.eventDate).format('MMM Do YYYY') : 'n/a'}</li>
                            <li><span>Upload Date:</span> {data.createdAt ? moment(data.createdAt).format('MMM Do YYYY') : 'n/a'}</li>
                        </ul>
                    </div>
                    <div className="details">
                        <ul>
                            <li><span>Account Name:</span> {data.selectors.accountname ? data.selectors.accountname : 'n/a'}</li>
                            <li><span>Account Address:</span> {data.accountAddress ? data.accountAddress : 'n/a'}</li>
                        </ul>
                    </div>
                    <div className="details">
                        <ul><li><span>Description:</span> {data.description ? data.description : 'n/a'}</li></ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default DetectClickOutside(PhotoEnlarger);