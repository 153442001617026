import React from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {authContext} from '../../../../../adalConfig';
import {toast} from 'react-toastify';

import Form from '../../../components/elements/Form/Form';
import Input from '../../../components/elements/Input/Input';
import RadioInput from '../../../components/elements/RadioInput/RadioInput';
import CheckboxPermissions from '../../../components/elements/CheckboxPermissions/CheckboxPermissions';
import SelectRole from '../../../components/elements/SelectRole/SelectRole';

import './UserForm.css';


export default class UserForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			firstName: "",
			lastName: "",
			email: "",
			adObjectId: "",
			roleId: "",
			profile: {
				permission: {
					brands: [],
					channels: [],
					clients: [],
					directors: [],
					divisions: [],
					departments: [],
					markets: [],
					productGroups: [],
					selectAll: {
						brands: false,
						channels: false,
						clients: false,
						directors: false,
						divisions: true,
						departments: false,
						markets: false,
						productGroups: false,
					}
				},
				planningtool: {
					access: "Read",
					prettyName: "Planning Tool",
				},
				nonpremiseplanning: {
					access: "Read",
					prettyName: "Non-Premise Planning"
				},
				programstandard: {
					access: "Read",
					prettyName: "Program Standard"
				},
				photoportal: {
					access: "Read",
					prettyName: "Photo Portal"
				},
				accountselection: {
					access: "Read",
					prettyName: "Account Selection"
				}
			},

			data: {},
			roles: this.props.roles,
			selectedRole: "",
			spiritsId: null,
			_activeTab: 0,
			_isLoading: false
		};
	}

	componentDidMount() {
		if (this.props.user) {
			this.setUser(this.props.user);
		}
		this.getPermissionsData();
	}

	componentDidUpdate(prevProps, prevState) {
		// if role is admin auto select everything in the permissions tab
		if (prevState.roleId !== this.state.roleId || prevState.roles !== this.state.roles) {
			if (this.state.roles) {
				let userRole = this.state.roles.find(role => role._id === this.state.roleId);
				let selectedRole = userRole ? userRole.name : "";
				if (selectedRole === 'admin') {
					this.setState(prevState => ({
						profile: {
							...prevState.profile,
							permission: {
								...prevState.profile.permission,
								selectAll: {
									brands: true,
									channels: true,
									clients: true,
									directors: true,
									divisions: true,
									departments: true,
									markets: true,
									productGroups: true
								}
							},
							planningtool: {
								...prevState.profile.planningtool,
								access: 'Admin'
							},
							nonpremiseplanning: {
								...prevState.profile.nonpremiseplanning,
								access: "Admin"
							},
							programstandard: {
								...prevState.profile.programstandard,
								access: 'Admin'
							},
							photoportal: {
								...prevState.profile.photoportal,
								access: 'Admin'
							},
							accountselection: {
								...prevState.profile.accountselection,
								access: "Admin"
							}
						}
					}));
				}
				this.setState({
					selectedRole: selectedRole
				});
			}
		}
	}

	setUser = (user) => {
		this.setState({
			firstName: user.firstName || "",
			lastName: user.lastName || "",
			email: user.email || "",
			roleId: user.roleId || "",
			profile: user.profile || {
				permission: {
					brands: [],
					channels: [],
					clients: [],
					directors: [],
					divisions: [],
					departments: [],
					markets: [],
					productGroups: [],
					selectAll: {
						brands: false,
						channels: false,
						clients: false,
						directors: false,
						divisions: true,
						departments: false,
						markets: false,
						productGroups: false
					}
				},
				planningtool: {
					access: "Read",
					prettyName: "Planning Tool"
				},
				nonpremiseplanning: {
					access: "Read",
					prettyName: "Non-Premise Planning"
				},
				programstandard: {
					access: "Read",
					prettyName: "Program Standard"
				},
				photoportal: {
					access: "Read",
					prettyName: "Photo Portal"
				},
				accountselection: {
					access: "Read",
					prettyName: "Account Selection"
				}
			},

			selectedRole: this.state.roles.find(role => role._id === user.roleId).name
		});
	}

	getPermissionsData = () => {
		const accessToken = localStorage.getItem('accessToken');
		if (!accessToken) return;
		this.setState({_isLoading: true});
		fetch(process.env.REACT_APP_SERVER_API_URL + 'product-groups/data',{
			method: "GET",
			headers: {
				'Authorization': accessToken,
			},
		}).then(response => response.json()
		).then(responseJson => {
			this.setState({data: JSON.parse(responseJson['data'])}, () => {
				this.state.data.productGroups.forEach( productGroup => {
					if(productGroup.name === "spirits"){
						this.setState({
							spiritsId: productGroup._id
						})
					}
				})
				this.setState({_isLoading: false});
			})
		}).catch(err => {
			console.log('err =', err);
		})
	}

	handleValidate = () =>{
		let {
			firstName,
			lastName,
			email,
			roleId
		} = this.state;
		let toastString = [];
		if (!firstName) {
			toastString.push("Please Enter A First Name")
		}
		if (!lastName) {
			toastString.push("Please Enter A Last Name")
		}
		if (!email) {
			toastString.push("Please Enter An Email Address")
		}
		if (!roleId) {
			toastString.push("Please Select A Role")
		}
		if (toastString.length) {
			toast(<React.Fragment>{toastString.map(str =>
				<p>{str}</p>)}</React.Fragment>, {className: "notification error"});
			return true
		}
		return false
	}

	handlePost = async () => {
		const accessToken = localStorage.getItem('accessToken');
		if (!accessToken) return;

		this.setState({_isLoading: true});
		let body = JSON.parse(JSON.stringify(this.state));
		delete body.data;
		delete body.roles;
		// post
		fetch(process.env.REACT_APP_SERVER_API_URL + 'user', {
			method: 'POST',
			headers: {
				'Authorization': accessToken,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body)
		}).then((response) => {
			if (response.status === 498) {
				authContext._renewIdToken((err, token) => {
					this.handlePost()
				})
			} else if (response.ok) {
				return response.json()
			} else {
				return response.json().then(Promise.reject.bind(Promise));
			}
		}).then((json) => {
			if (!json) return;
			toast("User Created Successfully", {className: "notification success"});
			this.setState({_isLoading: false});
			this.props.handleRefresh();
			this.props.handleCancel();
		}).catch((error) => {
			if (error.code && error.code === 11000) {
				toast("Duplicate Object ID found.", {className: "notification error"});
			} else {
				toast(error.message, {className: "notification error"});
			}
			this.setState({_isLoading: false})
		});
	}

	handlePut = () => {
		const accessToken = localStorage.getItem('accessToken');
		if (!accessToken) return;

		this.setState({_isLoading: true});
		let body = JSON.parse(JSON.stringify(this.state));
		delete body.data;
		delete body.roles;
		// put
		fetch(process.env.REACT_APP_SERVER_API_URL + 'user/' + this.props.user._id, {
			method: 'PUT',
			headers: {
				'Authorization': accessToken,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body)
		}).then((response) => {
			if (response.status === 498) {
				authContext._renewIdToken((err, token) => {
					this.handlePut()
				})
			} else if (response.ok) {
				return response.json()
			} else {
				return response.json().then(Promise.reject.bind(Promise));
			}
		}).then((json) => {
			if (!json) return;
			toast("User Updated Successfully", {className: "notification success"});
			this.setState({_isLoading: false});
			this.props.handleRefresh();
			this.props.handleCancel();
		}).catch((error) => {
			if (error.code && error.code === 11000) {
				toast("Duplicate Email found.", {className: "notification error"});
			} else {
				toast(error.message, {className: "notification error"});
			}
			this.setState({_isLoading: false})
		});
	}

	handleSubmit = (e) => {
		e.preventDefault();
		let hasErrors = this.handleValidate();
		if (!hasErrors) {
			if (this.props.user) {
				this.handlePut()
			} else {
				this.handlePost()
			}
		}
	}

	handlePlanToolCheckboxChange = (target, value, allSelected) => {
		this.setState(prevState => ({
			profile: {
				...prevState.profile,
				permission: {
					...prevState.profile.permission,
					[target]: value,
					selectAll: {
						...prevState.profile.permission.selectAll,
						[target]: allSelected
					}
				}
			}
		}));
	}

	handleChange = (target, value) => {
		this.setState({
			[target]: value
		});
	}

	handleRadioChange = (target, value) => {
		this.setState(prevState => ({
			profile: {
				...prevState.profile,
				[target]: {
					...prevState.profile[target],
					access: value
				}
			}
		}));
	}

	showPermissionsTab = (e) => {
		e.preventDefault();
		this.setState({
			_activeTab: this.state._activeTab + 1
		});
	}

	sendInvite = async (e) => {
		e.preventDefault();
		console.log("Send Invite");

		const accessToken = localStorage.getItem('accessToken');
		if (!accessToken) return;

		//post invite
		fetch(process.env.REACT_APP_SERVER_API_URL + 'invite/' + this.props.user.email,{
			method: 'POST',
			headers: {
				'Authorization': accessToken,
				'Content-Type': 'application/json'
			},
		}).then((response) => {
			if (response.ok) {
				return response.json()
			} else {
				return response.json().then(Promise.reject.bind(Promise));
			}
		}).then((json) => {
			if (!json) return;
			toast("Invite send successfully");
		}).catch((error) => {
			if (error.code && error.code === 11000) {
				toast("Duplicate Object ID found.", {className: "notification error"});
			} else {
				toast(error.message, {className: "notification error"});
			}
		});
	}

	renderRadioInput = () => {
		let profile = this.state.profile;
		let inputs = [];
		for(const key in profile){
			if(key !== "permission") {
				inputs.push(
					<div className="input-wrapper third" key={key}>
						<RadioInput prettyName={profile[key].prettyName} target={key}
									value={profile[key].access} handleChange={this.handleRadioChange}
									selectedRole={this.state.selectedRole}
						/>
					</div>
				);
			}
		}
		return inputs;
	}

	render() {
		return (
			<Form className="form user-form">
				<Tabs selectedTabClassName="is-active" selectedIndex={this.state._activeTab}
					  onSelect={_activeTab => this.setState({_activeTab: _activeTab})}>

					<div className="form-header">

						<TabList className="tab-links">
							<Tab className="tab">User Profile</Tab>
							<Tab className="tab" disabled={this.state._isLoading}>User Permission</Tab>
						</TabList>

						<button type="button" className="cancel" onClick={this.props.handleCancel}>Cancel</button>

					</div>

					<div className="form-body">

						<TabPanel>

							<div className="form-section">
								<div className="input-wrapper half">
									<Input label="First Name" id="firstName" value={this.state.firstName} type="text"
										   target="firstName" onChange={this.handleChange} required />
								</div>
								<div className="input-wrapper half">
									<Input label="Last Name" id="lastName" value={this.state.lastName} type="text"
										   target="lastName" onChange={this.handleChange} required />
								</div>
								<div className="input-wrapper full">
									<Input label="Email" id="email" value={this.state.email} type="email" target="email"
										   onChange={this.handleChange} required
										   disabled={this.props.user ? "readonly" : ""} />
								</div>
								<div className="input-wrapper full">
									<SelectRole label="Role" id="role" value={this.state.roleId} target="roleId"
												showLabel={true} roles={this.state.roles}
												onChange={this.handleChange} />
								</div>

								{this.renderRadioInput()}
							</div>

							<div className="form-footer">
								{this.props.user ?
									<a className="invite" onClick={this.sendInvite}>
										Send Invite
									</a> : <div />
								}
								<button className="button submit" disabled={this.state._isLoading}
										onClick={this.showPermissionsTab}>Next</button>
							</div>

						</TabPanel>

						<TabPanel>

							<CheckboxPermissions
								label="Product Groups"
								target="productGroups"
								data={this.state.data.productGroups}
								selectAll={this.state.profile.permission.selectAll.productGroups}
								value={this.state.profile.permission.productGroups}
								onChange={this.handlePlanToolCheckboxChange}
								selectedRole={this.state.selectedRole}
							/>

							<CheckboxPermissions
								label="Departments"
								target="departments"
								data={this.state.data.departments}
								selectAll={this.state.profile.permission.selectAll.departments}
								value={this.state.profile.permission.departments}
								onChange={this.handlePlanToolCheckboxChange}
								selectedRole={this.state.selectedRole}
							/>

							{/* <CheckboxPermissions
                        label="Divisions"
                        target="divisions"
                        data={this.state.data.divisions}
                        selectAll={this.state.profile.permission.selectAll.divisions}
                        value={this.state.profile.permission.divisions}
                        onChange={this.handleCheckboxChange}
                        acceptedProductGroups={this.state.productGroups}
                        selectedRole={this.state.selectedRole}
                     	/> */}

							<CheckboxPermissions
								label="Markets"
								target="markets"
								data={this.state.data.markets}
								productGroups={this.state.data.productGroups}
								selectAll={this.state.profile.permission.selectAll.markets}
								value={this.state.profile.permission.markets}
								onChange={this.handlePlanToolCheckboxChange}
								acceptedProductGroups={this.state.profile.permission.productGroups}
								selectedRole={this.state.selectedRole}
							/>

							<CheckboxPermissions
								label="Channels"
								target="channels"
								data={this.state.data.channels}
								selectAll={this.state.profile.permission.selectAll.channels}
								value={this.state.profile.permission.channels}
								onChange={this.handlePlanToolCheckboxChange}
								selectedRole={this.state.selectedRole}
							/>

							<CheckboxPermissions
								label="Brands"
								target="brands"
								data={this.state.data.brands}
								productGroups={this.state.data.productGroups}
								selectAll={this.state.profile.permission.selectAll.brands}
								value={this.state.profile.permission.brands}
								onChange={this.handlePlanToolCheckboxChange}
								acceptedProductGroups={this.state.profile.permission.productGroups}
								selectedRole={this.state.selectedRole}
							/>

							<CheckboxPermissions
								label="Managing Distributors"
								target="directors"
								data={this.state.data.distributors}
								selectAll={this.state.profile.permission.selectAll.directors}
								value={this.state.profile.permission.directors}
								onChange={this.handlePlanToolCheckboxChange}
								matchMarkets={this.state.profile.permission.markets}
								selectedRole={this.state.selectedRole}
							/>

							<div className="form-footer">
								<button className="button submit" disabled={this.state._isLoading}
										onClick={this.handleSubmit}>{this.props.user ? "Update User" : "Add New User"}</button>
							</div>

						</TabPanel>

					</div>

				</Tabs>

			</Form>
		)
	}
}
