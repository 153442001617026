import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {runWithAdal} from 'react-adal';
import FetchInterceptor from 'fetch-interceptor';
import {authContext} from './adalConfig';
import ReactGA from 'react-ga';
import App from './containers/App';

import './index.css';
import CryptoJS from "crypto-js";
import UpdatingPermissions from "./containers/Shared/Views/UpdatingPermissions";

authContext.log = function (level, message, error) {
    console.log(level, message, error);
};

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);


// The below is an interim solution for handling user token updates. We should transition launchpad state management to
// a standardized framework and handle all networking through the same pipeline.
const interceptor = FetchInterceptor.register({
	async onRequestFailure(response, request, controller) {
		if (response.status === 401){
			const body = await response.json();
			if (body.user && body.accessToken){
				ReactDOM.render((
					<UpdatingPermissions/>
				), document.getElementById('app'));
				const lastTokenReload = localStorage.getItem('lastTokenReload');
				// If we refreshed in the last 10 seconds forget all this. This is most likely a different kind of error.
				if (lastTokenReload && (new Date() - new Date(+lastTokenReload))/1000 < 10) return;
				const user = {
					...body.user,
					name: `${body.user.firstName} ${body.user.lastName}`,
					role: body.role.name
				};
				await localStorage.setItem('accessToken', body.accessToken);
				await localStorage.setItem('user', CryptoJS.AES.encrypt(JSON.stringify(user), process.env.REACT_APP_SECERT_KEY));
				await localStorage.setItem('lastTokenReload', new Date().getTime().toString());
			}
		}
	}
});

runWithAdal(authContext, () => {
    ReactDOM.render((
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    ), document.getElementById('app'));
});
