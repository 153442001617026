import React, {Component} from 'react';
import tableau from 'tableau-api';
import * as qs from "querystring";

import {OpenInNew} from "@material-ui/icons";
import "./ReportMenu.css"


export default class DashboardViewer extends Component {
	componentDidMount() {
		this.initViz()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.report.link !== prevProps.report.link || this.props.params !== prevProps.params) {
			this.initViz()
		}
	}

	initViz = (openTableauWindow = false) => {
		const accessToken = localStorage.getItem('accessToken');
		if (!accessToken) return;

		let report_url = this.props.report.link;
		let params = this.props.params;

		fetch(process.env.REACT_APP_SERVER_API_URL + "tableau",{
			method: 'GET',
			headers: {
				'Authorization': accessToken
			}
		}).then((response) => response.json()
		).then((responseJson) => {
			let server = process.env.REACT_APP_TABLEAU_SERVER;
			let site = process.env.REACT_APP_TABLEAU_TARGET_SITE;
			let vizUrl = '';
			if(params && Object.keys(params).length===0)
			{
				vizUrl = server + responseJson['data'] + '/t/' + site + '/views/' + report_url;
			} else{
				vizUrl = server + responseJson['data'] + '/t/' + site + '/views/' + report_url+'?'+qs.stringify(params);
			}
			if (openTableauWindow) {
				window.open(vizUrl);
			}
			else {
				const vizContainer = this.vizContainer;
				if (this.viz) {
					this.viz.dispose();
				}
				this.viz = new window.tableau.Viz(vizContainer, vizUrl);
			}
		}).catch((err) => {
			console.log("err ====> ", err);
		});
	};

	render() {
		return (
			<div className='dashboardViewer' align="center" >
				<div className="openNewWindow">
					<OpenInNew htmlColor="#FFFFFF" style={{backgroundColor: 'black', fontSize: 30}} onClick={() => this.initViz(true)}/>
				</div>
				<div ref={(div) => {this.vizContainer = div}} />
			</div>
		)
	}
}
