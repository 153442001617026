import React from 'react';
import {authContext} from '../../../adalConfig';
import './Views.css';


export default class DataCorrupted extends React.Component {

    componentDidMount() {
        const timer = setTimeout(this.logout, 3000);
        this.setState({timer});
    }

    componentWillUnmount() {
        clearTimeout(this.state.timer);
    }

    logout = async () => {
        await localStorage.removeItem('accessToken');
        await localStorage.removeItem('user');
        authContext.logOut();
    };

    render() {
        return (
            <div className="custom-view">
                <h3>Application updating...</h3>
                <p>
                    If you are not redirected in 3 seconds, please <a onClick={this.logout}>click here</a> to log out.
                </p>
            </div>
        )
    }
}