import React, { Component } from 'react';

export default class FilterDisplay extends Component {
    getDisplayString = (obj) => {
        if (obj.value) {
            if (['brand', 'channel', 'market', 'campaign', 'tag'].includes(obj.id)) {
                let displayString = "";
                try {
                    obj.value.forEach((item, index) => {
                        displayString += item.label + ", ";
                        if (index >= 3) {
                            throw Error;
                        }
                    });
                    return displayString.slice(0, -2);
                } catch (e) {
                    return displayString + "...";
                }
            } else {
                return obj.display;
            }
        }
    }

    render() {
        const activeFilters = [];
        const displayString = [];

        for (const filter in this.props.filters) {
            if (filter) {
                const obj = this.props.filters[filter];
                if (obj.value) {
                    activeFilters.push(obj);
                    displayString.push(this.getDisplayString(obj));
                }
            }
        }

        return (
            <ul className="active-filters">
                <li>Filters</li>

                {activeFilters.length === 0 && <li>No Active Filters</li>}
                    {
                        activeFilters.map(function(filterObj, index) {
                            return (displayString[index] === "" ? "" :
                                <li key={index}>{filterObj.name} : {displayString[index]}</li>);
                        })
                    }
            </ul>
        )
    }
}